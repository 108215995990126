import { notification } from 'antd'

import * as constants from '../constants'

const initialState = { isResult: 'default' }

export default function reducer (state = initialState, action) {
    switch (action.type) {
        case constants.syncField.success: {
            return { results: action.payload, isResult: true }
        }

        case constants.syncField.error: {
            if (action.payload?.status === 400) {
                notification.error({
                    message: 'Добавление невозможно',
                    description: 'Выбранный слой уже добавлен другим пользователем'
                })
            }
            return { isResult: false, error: 'backend error' }
        }

        default:
            return state
    }
}
