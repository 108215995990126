import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { useClassName } from 'utils/cn'
import {
    Col, Collapse, Row, Modal, Form, Tabs, Spin,
    Tooltip
} from 'antd'
import Icon, { DownloadOutlined } from '@ant-design/icons'


import { useDispatch, useSelector } from 'react-redux'
import {
    useHistory, useRouteMatch, Link
} from 'react-router-dom'
import moment from 'moment'
import {
    Button
} from '../../../components'


import ListIcon from '../../../../public/images/calculator/list2_stroke.svg'

import { selectors, actions } from '../../../models'
import NormativeNutrition from './normative-nutrition/index'
import Interpretation from './interpretation'
import InterpretationTable from './interpretation/table'
import AgroResults from './agro-results'
import MeteodataAnalysis from './meteodata-analysis'
import Distribution from './distribution'
import Phases from '../../../containers/item-phases/component'
import { generatePdf } from '../../../containers/pdf-generator'
import Login from './modal'
import XLSXgenerator from '../../../containers/xlsx-generator'
import AgrotechnicalEvents from './agrotechnical-events'
import LeafDiagnostics from './leaf-diagnostics'
import MycologicalInterpretation from './mycological-interpretation/component'
import { getCurrentCalculatorMordovia } from '../../../models/mordovia/selectors'
import { curLeafResults } from '../../../models/leaf-diagnostics/selectors'

const {
    getCurCalculatorField,
    getCurrentForecastCalculatorMordovia,
    isAuth,
    isCalculateCompleted,
    isCalculateLoading,
    getUserId,
    getUser,
    curNormative,
    curNutrition,
    getRegionNormCultureCatalog,
    curPoints,
    getFieldUser,
    calculatorsGroup,
    isForecastLoading
} = selectors
const { updateCalculatorsMordoviaForecast, updateCalculate } = actions
const { Panel } = Collapse
const { TabPane } = Tabs

export default ({ vegaKey, setCurPoint }) => {
    const cn = useClassName('calculators')
    const cn_info = useClassName('calculator')
    const match = useRouteMatch()
    const { t } = useTranslation('mordovia')
    const { t: tPDF } = useTranslation('calculators')
    const { t: interT } = useTranslation('interpretation')
    const dispatch = useDispatch()

    const [form] = Form.useForm()

    const history = useHistory()
    const [activeKeysState, setActiveKeys] = useState([])
    // const calculator = useSelector(state => curCalculate(state));
    const normative = useSelector(state => curNormative(state))
    const nutrition = useSelector(state => curNutrition(state))
    const cultures = useSelector(state => getRegionNormCultureCatalog(state))
    const calculator = useSelector(state => getCurCalculatorField(state))
    const forecast = useSelector(state => getCurrentForecastCalculatorMordovia(state))
    const forecastIsLoading = useSelector(state => isForecastLoading(state))
    const calcGroup = useSelector(state => calculatorsGroup(state))
    const isAuthState = useSelector(state => isAuth(state))
    const isCompletedState = useSelector(state => isCalculateCompleted(state))
    const isLoading = useSelector(state => isCalculateLoading(state))
    const userID = useSelector(state => getUserId(state))
    const fieldUserID = useSelector(state => getFieldUser(state))
    const user = useSelector(state => getUser(state))
    const points = useSelector(state => curPoints(state))
    const currentMordovia = useSelector(state => getCurrentCalculatorMordovia(state))
    const currentLeaf = useSelector(state => curLeafResults(state))

    const [active, setActive] = useState(false)
    const [isCompleted, setCompleted] = useState(false)
    const [isOwner, setIsOwner] = useState(false)
    const [isSend, setIsSend] = useState(false)
    const [visible, setVisible] = useState(false)
    const [modalText, setModalText] = useState('')
    const [recommended, setRecommended] = useState({})
    const [cultureId, setCultureId] = useState(null)

    const { year } = match.params

    useEffect(() => {
        if (calcGroup && calcGroup[0]?.normative?.culture?.id) {
            setCultureId(calcGroup[0]?.normative?.culture?.id)
        }
    }, [normative, calculator, calcGroup])

    useEffect(() => {
        setCompleted(isCompletedState)
    }, [isCompletedState])

    useEffect(() => {
        setIsOwner(fieldUserID === userID)
    }, [fieldUserID, userID])

    useEffect(() => {
        isLoading && setVisible(false)
    }, [isLoading])

    useEffect(() => {
        const activeKeys = [...activeKeysState]
        const newCulture = calculator?.culture?.id

        if (newCulture && forecast.culture?.id && forecast.culture?.id !== newCulture) {
            dispatch(updateCalculatorsMordoviaForecast(
                {
                    id: vegaKey,
                    body: {
                        year: year,
                        culture: newCulture,
                        sowing_date: forecast.sowing_date
                    }
                }
            ))
        }
    }, [calculator, forecast])

    useEffect(() => {
        if (isAuthState && isSend) {
            // localStorage.setItem('prevPage', `/representation/${vegaKey}`);
            history.push(`/representation/${vegaKey}`)
            setActive(false)
        }
    }, [isAuthState, isSend])


    const handleClick = () => {
        if (isAuthState) {
            history.push(`/representation/${vegaKey}`)
        } else {
            setActive(true)
            setIsSend(true)
        }
    }

    const onCollapseChange = (value) => {
        setActiveKeys([...value])
    }

    const handleCancel = () => {
        setActive(false)
    }

    // const handleSubmit = () => {
    //     setIsSend(true)
    // }

    const generatedPDF = () => {
        generatePdf(tPDF, calculator)
    }

    const fixedCalculate = () => {
        setVisible(true)
        const type = t('calculate type for save') // или по данным эксперта
        const name = calculator?.normative?.culture?.name
        const planned_yield = calculator?.normative?.planned_yield

        const text = `${t('text type')} ${t('text name', { value: name })} ${t('text yield', { value: planned_yield })}`
        setModalText(text)
    }

    const unfixedCalculate = () => {
        dispatch(updateCalculate({
            id: calculator.id,
            values: {
                name: calculator.name,
                calculator_type: calculator.calculator_type,
                is_done: false
            }
        }))
    }

    const onCloseCalculate = () => {
        dispatch(updateCalculate({
            id: calculator.id,
            values: {
                name: calculator.name,
                calculator_type: calculator.calculator_type,
                is_done: true
            }
        }))
    }

    const onCancel = () => {
        setVisible(false)
    }

    // const setRecommendedToSend = (data) => {
    //     setRecommended(data)
    // }

    useEffect(() => {
        form.setFieldsValue({ culture: normative?.culture?.name, sowing_date: moment(forecast.sowing_date, 'YYYY-MM-DD') })
    }, [forecast, cultures, normative])

    // const onValuesChange = (value, values) => {
    //     dispatch(updateCalculatorsMordoviaForecast(
    //         {
    //             id: vegaKey,
    //             body: {
    //                 culture: normative?.culture?.id,
    //                 sowing_date: moment(values.sowing_date).format('YYYY-MM-DD')
    //             }
    //         }
    //     ))
    // }

    // const isFasie = user?.groups?.includes('fasie')

    const hideAllTabs = () => {
        setActiveKeys([])
    }

    return (
        <>
            {/* {!isOwner && (
                <div className={cn_info('info')}>
                    <div className={cn_info('info__inner')}>
                        {t('not your field')}
                    </div>
                </div>
            )} */}
            <Login active={active} onCancel={handleCancel} />
            <Row style={{ marginBottom: '25px' }}>
                <Button disabled={activeKeysState?.length < 1} size="default" type="secondary" onClick={hideAllTabs}>
                    {t('hide all tabs')}
                </Button>
            </Row>
            <Spin spinning={forecastIsLoading}>
                <Collapse
                    activeKey={activeKeysState}
                    onChange={onCollapseChange}
                    expandIconPosition="right"
                    className={cn('collapse')}>
                    {points?.length > 0 && (
                        <Panel header={<><Icon component={ListIcon} />{t('calculator agro results')}</>}
                            key="agro-results">
                            <AgroResults setCurPoint={setCurPoint} />
                        </Panel>
                    )}
                    {(calcGroup?.filter(x => x.calculator_type !== 12)?.length > 0 || points?.length > 0) ? (
                        <Panel header={<><Icon component={ListIcon} />{t('calculator data')}</>}
                            key="interpretation">
                            <InterpretationTable isCompleted={!isAuthState || isCompleted || !isOwner} />
                            <Interpretation
                                year={year}
                                isCompleted={!isAuthState || isCompleted || !isOwner} />
                        </Panel>
                    ) : (
                        <Panel disabled header={(
                            <Tooltip placement="bottom" title={t('interpretation missing tooltip')}>
                                <span className="very-disabled-panel"><Icon component={ListIcon} />{t('calculator data')}</span>
                            </Tooltip>
                        )}
                            key="interpretation">
                            <>
                                <InterpretationTable isCompleted={!isAuthState || isCompleted || !isOwner} />
                                <Interpretation
                                    year={year}
                                    isCompleted={!isAuthState || isCompleted || !isOwner} />
                            </>
                        </Panel>
                    )}

                    {/* {!isFasie && (
                        <>
                            {points?.length > 0 && (
                                <Panel header={<><Icon component={ListIcon} />{t('calculator agro results')}</>}
                                    key="agro-results">
                                    <AgroResults setCurPoint={setCurPoint} />
                                </Panel>
                            )}
                            <Panel header={<><Icon component={ListIcon} />{t('calculator data')}</>}
                                key="interpretation">
                                <InterpretationTable isCompleted={!isAuthState || isCompleted || !isOwner} />
                                <Interpretation
                                    year={year}
                                    isCompleted={!isAuthState || isCompleted || !isOwner} />
                            </Panel>
                        </>
                    )} */}
                    <Panel header={<><Icon component={ListIcon} />{t('calculator parameters normative')}</>}
                        key="normative">
                        {calcGroup?.filter(calc => calc.calculator_type === 7)?.length < 1 ? (
                            <>
                                <Row align="middle">{t('distr text pt1')}</Row>
                                <Row align="middle"><Link to='/calculator/6' className="hyperlink">{t('distr text pt2')}</Link></Row>
                            </>
                        ) : (
                            <Tabs defaultActiveKey="1">
                                {
                                    calcGroup?.map(calc => {
                                        if (calc.calculator_type === 7) {
                                            return (
                                                <TabPane tab={
                                                    calc?.type === 'field'
                                                        ? (calc.calculator_type === 7 && `${interT(calc.type)}${calc?.date ? `- ${calc?.date}` : ''}`)
                                                        : (
                                                            calc.type === 'zones'
                                                                ? `${interT(calc.type)} ${calc.zones?.map((el, i) => `${interT(el)}${i < calc.length - 1 ? ', ' : ''}`)}${calc?.date ? `- ${calc?.date}` : ''}`
                                                                : `${interT(calc.type)} ${calc.points?.join(', ')}${calc?.date ? `- ${calc?.date}` : ''}`
                                                        )
                                                }
                                                    key={calc.id}>
                                                    <NormativeNutrition
                                                        calculator={calc}
                                                        normative={normative.id ? normative : calc.normative}
                                                        nutrition={nutrition.id ? nutrition : calc.nutrition}
                                                        distribution={calc.distribution}
                                                        isCompleted={!isAuthState || isCompleted || !isOwner} />
                                                    {!!calc.normative?.id
                                                        && (isAuthState || !!calc.distribution?.id) && (
                                                            <div style={{ marginTop: '40px' }}>
                                                                <Distribution
                                                                    type="AGROCHEMICAL_NORMATIVE_DISTRIBUTION"
                                                                    vegaKey={vegaKey}
                                                                    calculator={calc}
                                                                    normative={normative.id ? normative : calc.normative}
                                                                    nutrition={nutrition.id ? nutrition : calc.nutrition}
                                                                    distribution={calc.distribution}
                                                                    isCompleted={!isAuthState || isCompleted || !isOwner}
                                                                    {...recommended} />
                                                            </div>
                                                        )}
                                                    <Row className={cn('button')} style={{ marginTop: '30px', marginBottom: '30px' }}>
                                                        <Col>
                                                            {!isAuthState && (
                                                                <Button
                                                                    type="primary"
                                                                    onClick={handleClick}
                                                                    size="large">
                                                                    <DownloadOutlined />
                                                                    {t('change data')}
                                                                </Button>
                                                            )}
                                                            <Button
                                                                type="primary"
                                                                onClick={generatedPDF}
                                                                size="large">
                                                                <DownloadOutlined />
                                                                {t('generate pdf')}
                                                            </Button>
                                                            <XLSXgenerator />
                                                            {!isCompleted && isOwner && (
                                                                <Button
                                                                    type="primary"
                                                                    disabled={!isAuthState || isCompleted || !isOwner}
                                                                    onClick={fixedCalculate}
                                                                    size="large">
                                                                    {t('fixed calculate')}
                                                                </Button>
                                                            )}
                                                            {isCompleted && isOwner && (
                                                                <Button
                                                                    type="primary"
                                                                    disabled={!isAuthState || !isOwner}
                                                                    onClick={unfixedCalculate}
                                                                    size="large">
                                                                    {t('unfixed calculate')}
                                                                </Button>
                                                            )}
                                                            <Modal
                                                                title={t('fixed title')}
                                                                visible={visible}
                                                                okText={t('ok text')}
                                                                cancelText={t('cancel text')}
                                                                onOk={onCloseCalculate}
                                                                onCancel={onCancel}>
                                                                {modalText}
                                                            </Modal>
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            )
                                        }
                                    })
                                }
                            </Tabs>
                        )}
                    </Panel>
                    {currentLeaf?.id && (
                        <Panel
                            header={<><Icon component={ListIcon} />{t('leaf diagnostics')}</>}
                            key="leafdiagnostics">
                            <LeafDiagnostics />
                        </Panel>
                    )}
                    {currentMordovia?.calculator?.year_group?.years?.filter(yr => yr?.mycological_calculator)?.length > 0 && (
                        <Panel
                            header={<><Icon component={ListIcon} />{t('mycological interpretation')}</>}
                            key="mycological_interpretation">
                            <MycologicalInterpretation />
                        </Panel>
                    )}
                </Collapse>
            </Spin>
            <Collapse
                expandIconPosition="right"
                className={cn('collapse')}>
                {forecast.culture && (
                    <>
                        {forecast?.items?.length > 0 ? (
                            <Panel
                                header={<><Icon component={ListIcon} />{t('calculator phases')}</>}
                                key="phases">
                                <Phases
                                    currentCulture={cultureId}
                                    vegaKey={vegaKey}
                                    year={year}
                                    isCompleted={!isAuthState || isCompleted || !isOwner} />
                            </Panel>
                        ) : (
                            <Panel
                                disabled
                                header={(
                                    <Tooltip placement="bottom" title={t('phases missing tooltip')}>
                                        <Icon component={ListIcon} />{t('calculator phases')}
                                    </Tooltip>
                                )}
                                key="phases">
                                <Phases
                                    currentCulture={cultureId}
                                    vegaKey={vegaKey}
                                    year={year}
                                    isCompleted={!isAuthState || isCompleted || !isOwner} />
                            </Panel>
                        )}
                        <Panel
                            header={<><Icon component={ListIcon} />{t('agrotechnical events')}</>}
                            key="events">
                            <AgrotechnicalEvents />
                        </Panel>
                    </>
                )}
                <Panel
                    header={<><Icon component={ListIcon} />{t('meteodata analysis')}</>}
                    key="interpretation">
                    <MeteodataAnalysis />
                </Panel>
            </Collapse>
        </>
    )
}
