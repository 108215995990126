import React, { Component } from 'react'
import { withTranslation } from 'react-i18next'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import moment from 'moment'
import {
    Col, Form, Row, Select, Button, Space
} from 'antd'
import cn from '../../../utils/cn'

import { selectors, actions } from '../../../models'
import { InfoMessage, Table, TableFooter, Tooltip } from '../../../components'

import NewDataForm from './new-data-form'
import ColumnSelect from './column-select'
import { toFixed } from '../../../utils/utils'

const {
    getObjectByLayer
} = actions
const {
    getUser
} = selectors

const mstp = state => ({
    user: getUser(state)
})

const mdtp = ({
    getObjectByLayer
})

const ROW_DIVIDER = 10
const MAX_RECORDS_IN_PAGINATOR = 40
const DEFAULT_TABLE_PAGE = 1

@withRouter
@connect(mstp, mdtp)
@withTranslation('calculators table')
@cn('calculators-table')
export default class CalculatorsTable extends Component {
    constructor (props) {
        super(props)
        this.formRef = React.createRef()
    }

    state = {
        page: DEFAULT_TABLE_PAGE,
        page_size: ROW_DIVIDER,
        infoMessageVisible: false,
        deleteId: null,
        sortingOrder: null
    };

    componentDidMount () {
        setTimeout(() => {
            this.getFilteredFields()
        }, 500)
    }

    onPageSizeChange = (page_size) => {
        const {
            page,
            layer,
            year,
            owner_user,
            culture,
        } = this.state

        this.setState({
            page_size
        }, () => {
            const copiedVals = {
                page: page,
                page_size: page_size,
                year: year,
                layer: layer,
                owner_user: owner_user,
                culture: culture
            }
            const translatedValues = JSON.stringify(copiedVals)
            window.onbeforeunload = () => {
                sessionStorage.setItem('object-registry/filters', translatedValues)
            }

            this.getFilteredFields()
        })
    };

    onCurrentPageChange = (page) => {
        const {
            page_size,
            layer,
            year,
            owner_user,
            culture,
        } = this.state

        this.setState({
            page
        }, () => {
            const copiedVals = {
                page: page,
                page_size: page_size,
                year: year,
                layer: layer,
                owner_user: owner_user,
                culture: culture
            }
            const translatedValues = JSON.stringify(copiedVals)
            window.onbeforeunload = () => {
                sessionStorage.setItem('object-registry/filters', translatedValues)
            }

            this.getFilteredFields()
        })
    };

    get getYears () {
        const date = new Date()
        let year = Number(date.getFullYear())
        // let step = 10;
        let step = 6
        const years = []
        // while (step >= 0 && year !== 2017) {
        while (year !== 2017) {
            years.push(year--)
            step--
        }
        return years
    }

    get initRequest () {
        const { page, page_size } = this.state
        const page_size_url = `page_size=${page_size}`
        const page_url = `page=${page}`
        return `?${page_size_url}&${page_url}`
    }

    getFilteredFields = () => {
        const {
            page_size,
            page,
            year,
            owner_user,
            culture,
            sortingOrder
        } = this.state
        let sorterString
        if (sortingOrder) {
            const { order, columnKey } = sortingOrder

            let resColKey = columnKey

            if (resColKey === 'object_type') {
                resColKey = 'registry_object'
            } else if (resColKey === 'owner') {
                resColKey = 'user'
            }

            if (order === 'ascend') {
                sorterString = columnKey
            } else if (order === 'descend') {
                sorterString = `-${columnKey}`
            }
        }

        const { generalLayer = null, getObjectByLayer, setGeneralLayer } = this.props

        const savedProps = sessionStorage.getItem('object-registry/filters')

        if (savedProps?.length > 0) {
            const preFilters = JSON.parse(savedProps)
            sessionStorage.removeItem('object-registry/filters')

            if (!generalLayer && preFilters?.layer) {
                setGeneralLayer(preFilters?.layer)
            }

            this.formRef.current?.setFieldsValue({
                layer: preFilters?.layer,
                year: preFilters?.year,
                culture: preFilters?.culture,
                owner_user: preFilters?.owner_user
            })

            this.setState({
                layer: preFilters?.layer,
                page: preFilters?.page,
                page_size: preFilters?.page_size,
                year: preFilters?.year,
                culture: preFilters?.culture,
                owner_user: preFilters?.owner_user
            }, () => {
                getObjectByLayer({
                    page: preFilters?.page,
                    pageSize: preFilters?.page_size,
                    layerId: preFilters?.layer,
                    sorterString: sorterString,
                    filterObj: {
                        ownerUser: preFilters?.owner_user,
                        year: preFilters?.year,
                        culture: preFilters?.culture
                    }
                })
            })
        } else {
            if (generalLayer) {
                getObjectByLayer({
                    page: page,
                    pageSize: page_size,
                    layerId: generalLayer ?? year,
                    sorterString: sorterString,
                    filterObj: {
                        ownerUser: owner_user,
                        year: year,
                        culture: culture
                    }
                })
            }
        }
    };

    onChange = (value, values) => {
        const {
            page_size,
            page
        } = this.state
        this.setState(value, () => {
            if (Object.keys(value)?.[0] === 'layer') {
                const copiedVals = { layer: values?.layer }
                copiedVals.page = page
                copiedVals.page_size = page_size
                const translatedValues = JSON.stringify(copiedVals)
                window.onbeforeunload = () => {
                    sessionStorage.setItem('object-registry/filters', translatedValues)
                }

                if (this.formRef.current?.getFieldsValue()?.year) {
                    this.formRef.current?.setFieldsValue({
                        year: null,
                        culture: null,
                        owner_user: null
                    })
                } else {
                    this.formRef.current?.setFieldsValue({
                        // year: null,
                        culture: null,
                        owner_user: null
                    })
                }

                this.setState({
                    year: null,
                    culture: null,
                    owner_user: null
                }, () => {
                    this.setState({ page: 1 }, () => {
                        this.getFilteredFields()
                    })
                })
            } else {
                const copiedVals = { ...values }
                copiedVals.page = page
                copiedVals.page_size = page_size
                const translatedValues = JSON.stringify(copiedVals)
                window.onbeforeunload = () => {
                    sessionStorage.setItem('object-registry/filters', translatedValues)
                }
                this.setState({ page: 1 }, () => {
                    this.getFilteredFields()
                })
            }
        })
    };

    onMount = (form, { formInit, formFieldsClear, formClear }) => {
        this.setState({
            form, formInit, formFieldsClear, formClear
        })
    };

    get requestsColumns () {
        const {
            activeColumns = [],
            t,
            openField = () => { },
            dataSource
        } = this.props
        const { year, culture } = this.state

        const getAllIndexes = (arr, val) => {
            var indexes = [], i
            for (let i = 0; i < arr.length; i++) {
                if (!year && !culture) {
                    if (arr[i]?.id === val) {
                        indexes.push(i)
                    }
                } else if (year && culture) {
                    if (
                        arr[i]?.id === val
                        && arr[i]?.culture?.name?.toLowerCase() === culture?.toLowerCase()
                        && arr[i]?.year === year
                    ) {
                        indexes.push(i)
                    }
                } else if (year && !culture) {
                    if (
                        arr[i]?.id === val
                        && arr[i]?.year === year
                    ) {
                        indexes.push(i)
                    }
                } else if (!year && culture) {
                    if (
                        arr[i]?.id === val
                        && arr[i]?.culture?.name?.toLowerCase() === culture?.toLowerCase()
                    ) {
                        indexes.push(i)
                    }
                }
            }
            return indexes
        }

        return [
            {
                title: t('header title object name'),
                dataIndex: 'field_num',
                key: 'field_num',
                fixed: 'left',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: (<a style={{ cursor: 'pointer' }} onClick={() => openField(record)}>{value}</a>),
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: (<a style={{ cursor: 'pointer' }} onClick={() => openField(record)}>{value}</a>),
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title object type'),
                dataIndex: ['registry_object', 'object_type'],
                key: 'object_type',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value ? t(value) : null,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value ? t(value) : null,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title region'),
                dataIndex: ['region', 'name'],
                key: 'region',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title raion'),
                dataIndex: ['district', 'name'],
                key: 'district',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title owner'),
                dataIndex: ['owner_user', 'name'],
                key: 'owner_user',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title user'),
                dataIndex: ['user', 'username'],
                key: 'owner',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title object area'),
                dataIndex: 'area',
                key: 'area',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value ? toFixed(value, 2) : null,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value ? toFixed(value, 2) : null,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                },
            },
            {
                title: t('header title soil type'),
                dataIndex: ['soil_type', 'value', 'name'],
                key: 'soil_type',
                sorter: true,
                render: (value, record, index) => {
                    const occurrencesNumber = getAllIndexes(dataSource, record?.id)
                    if (occurrencesNumber?.length > 1) {
                        const currentItemIdx = dataSource?.findIndex((e) => (e?.id === record?.id && e?.year === record?.year))
                        if (currentItemIdx === occurrencesNumber[0]) {
                            return {
                                children: value,
                                props: {
                                    rowSpan: occurrencesNumber?.length
                                }
                            }
                        } else {
                            return {
                                props: { rowSpan: 0 }
                            }
                        }
                    } else {
                        return {
                            children: value,
                            props: { rowSpan: occurrencesNumber?.length }
                        }
                    }
                }
            },
            {
                title: t('header title year'),
                dataIndex: 'year',
                key: 'years'
            },
            {
                title: t('header title mechanical composition'),
                dataIndex: ['mechanical_composition', 'value', 'name'],
                key: 'mechanical_composition'
            },
            {
                title: t('header title soil humidity'),
                dataIndex: ['degree_of_soil_moisture', 'value', 'name'],
                key: 'degree_of_soil_moisture'
            },
            {
                title: t('header title sowing date'),
                dataIndex: 'sowing_date',
                key: 'sowing_date',
                render: (value) => value ? moment(value)?.format('DD.MM.YYYY') : '-'
            },
            {
                title: t('header title current culture'),
                dataIndex: ['culture', 'name'],
                key: 'culture'
            },
            {
                title: t('header title preceding culture'),
                dataIndex: ['preceding_culture', 'name'],
                key: 'preceding_culture'
            },
            {
                title: t('header title planned yield'),
                dataIndex: ['planned_yield', 'value'],
                key: 'planned_yield',
                render: (value) => value ? toFixed(value, 2) : '-'
            },
            {
                title: t('header title depth'),
                dataIndex: ['depth_of_arable_layer', 'value'],
                key: 'depth_of_arable_layer',
                render: (value) => value ? toFixed(value, 2) : '-'
            },
            {
                title: t('header title action year'),
                dataIndex: ['fertilizer_action_year', 'value', 'name'],
                key: 'fertilizer_action_year'
            }
        ].filter(x => activeColumns?.includes(x?.key))
    }

    removeCalculator = () => {
        const { removeCalculatorAGRO, getCalculatorsAGRO } = this.props
        const { deleteId } = this.state

        removeCalculatorAGRO(deleteId)

        this.hideDeleteModal()
    };

    hideDeleteModal = () => {
        this.setState({ infoMessageVisible: false })
    };

    showDeleteModal = id => {
        this.setState({
            deleteId: id,
            infoMessageVisible: true
        })
    };

    handleObjectsSetup = () => {
        const {
            generalLayer
        } = this.props

        if (generalLayer && generalLayer > 0) {
            window.location.replace(`/layer-objects/${generalLayer}/`)
        }
    };

    get filterItems () {
        const { dataSize } = this.props
        const items = []

        for (let i = 1; i < dataSize; i += 1) {
            if (i % ROW_DIVIDER === 0) {
                items.push({ value: `${i}`, title: `${i}` })
            }

            if (i > MAX_RECORDS_IN_PAGINATOR) break
        }

        return items
    }

    get filteredDataset () {
        const {
            dataSource
        } = this.props
        const {
            year, culture
        } = this.state
        if (year && !culture) {
            let arrayConstructor = []
            dataSource?.forEach(inpData => {
                if (inpData?.year === year) {
                    let filteredYears = inpData?.year_group?.years?.filter(x => x?.year === year)
                    if (filteredYears?.length < 1) {
                        filteredYears = [{}]
                    }
                    inpData.year_group.years = filteredYears
                    arrayConstructor.push(inpData)
                }
            })
            return arrayConstructor?.map(x => {
                x.uniqueRowKey = `${x?.id}-${x?.year}`
                return x
            })
        }

        if (!year && culture) {
            let arrayConstructor = []
            dataSource?.forEach(inpData => {
                if (inpData?.culture?.name?.toLowerCase() === culture?.toLowerCase()) {
                    let filteredYears = inpData?.year_group?.years?.filter(x => x?.culture?.name?.toLowerCase() === culture?.toLowerCase())
                    if (filteredYears?.length < 1) {
                        filteredYears = [{}]
                    }
                    inpData.year_group.years = filteredYears
                    arrayConstructor.push(inpData)
                }
            })
            return arrayConstructor?.map(x => {
                x.uniqueRowKey = `${x?.id}-${x?.year}`
                return x
            })
        }

        if (year && culture) {
            let arrayConstructor = []
            dataSource?.forEach(inpData => {
                if (inpData?.year === year && inpData?.culture?.name?.toLowerCase() === culture?.toLowerCase()) {
                    let filteredYears = inpData?.year_group?.years?.filter(x => x?.year === year && x?.culture?.name?.toLowerCase() === culture?.toLowerCase())
                    if (filteredYears?.length < 1) {
                        filteredYears = [{}]
                    }
                    inpData.year_group.years = filteredYears
                    arrayConstructor.push(inpData)
                }
            })
            return arrayConstructor?.map((x, idx) => {
                x.uniqueRowKey = `${x?.id}-${x?.year}`
                return x
            })
        }

        return dataSource?.map(x => {
            x.uniqueRowKey = `${x?.id}-${x?.year}`
            return x
        })
    }

    handleOpenFieldYear = (yearChosen, record) => {
        const {
            openFieldYear
        } = this.props
        const {
            page,
            page_size,
            layer,
            year,
            owner_user,
            culture,
        } = this.state

        openFieldYear(yearChosen, record, {
            page: page,
            page_size: page_size,
            year: year,
            layer: layer,
            owner_user: owner_user,
            culture: culture
        })
    }

    handleTableChange = (pagination, filters, sorter, extra) => {
        this.setState({
            sortingOrder: sorter
        }, () => {
            this.setState({ page: 1 }, () => {
                this.getFilteredFields()
            })
        })
    }

    render () {
        const { cn } = this
        const {
            setGeneralLayer = () => { },
            activeColumns = [],
            setActiveColumns = () => { },
            fullUsersList = [],
            dataSize,
            openModal = () => { },
            layerOptions = [],
            cultureList,
            generalLayer,
            openField = () => { },
            t
        } = this.props
        const { infoMessageVisible, page_size, page, layer, year, culture, owner_user } = this.state

        const columnSelectOptions = [
            {
                title: t('header title object name'),
                dataIndex: 'field_num',
                key: 'field_num',
                sorter: true,
                render: (value, record) => (<a style={{ cursor: 'pointer' }} onClick={() => openField(record)}>{value}</a>)
            },
            {
                title: t('header title object type'),
                dataIndex: ['registry_object', 'object_type'],
                key: 'object_type',
                sorter: true,
                render: value => value ? t(value) : '-'
            },
            {
                title: t('header title region'),
                dataIndex: ['region', 'name'],
                sorter: true,
                key: 'region'
            },
            {
                title: t('header title raion'),
                dataIndex: 'district',
                sorter: true,
                key: 'district'
            },
            {
                title: t('header title owner'),
                dataIndex: 'owner_user',
                defaultSortOrder: 'descend',
                sorter: true,
                key: 'owner_user'
            },
            {
                title: t('header title user'),
                dataIndex: 'owner',
                defaultSortOrder: 'descend',
                sorter: true,
                key: 'owner'
            },
            {
                title: t('header title year'),
                dataIndex: 'years',
                key: 'years',
                render: value => value?.length > 0 ? value?.join(', ') : value
            },
            {
                title: t('header title object area'),
                dataIndex: 'area',
                sorter: true,
                key: 'area'
            },
            {
                title: t('header title soil type'),
                dataIndex: 'soil_type',
                sorter: true,
                key: 'soil_type'
            },
            {
                title: t('header title mechanical composition'),
                dataIndex: 'mechanical_composition',
                key: 'mechanical_composition'
            },
            {
                title: t('header title soil humidity'),
                dataIndex: 'degree_of_soil_moisture',
                key: 'degree_of_soil_moisture'
            },
            {
                title: t('header title sowing date'),
                dataIndex: 'sowing_date',
                key: 'sowing_date'
            },
            {
                title: t('header title current culture'),
                dataIndex: ['culture', 'name'],
                key: 'culture'
            },
            {
                title: t('header title preceding culture'),
                dataIndex: ['preceding_culture', 'name'],
                key: 'preceding_culture'
            },
            {
                title: t('header title planned yield'),
                dataIndex: 'planned_yield',
                key: 'planned_yield'
            },
            {
                title: t('header title depth'),
                dataIndex: 'depth_of_arable_layer',
                key: 'depth_of_arable_layer'
            },
            {
                title: t('header title action year'),
                dataIndex: 'fertilizer_action_year',
                key: 'fertilizer_action_year'
            }
        ]

        const actionButton = {
            key: 'actions',
            fixed: 'right',
            // width: 25,
            // ellipsis: true,
            render: (val, record) => record?.year ? (<Button style={{ width: '100%', height: '100%' }} onClick={() => this.handleOpenFieldYear(year, record)}>{t('open')}</Button>) : (<></>)
        }

        return layerOptions?.length < 1 ? (
            <NewDataForm openModal={openModal} />
        ) : (
            <div className={cn()}>
                <Form
                    ref={this.formRef}
                    onValuesChange={this.onChange}
                    name="fields">
                    <Row gutter={20}>
                        <Col span={6} className="field">
                            <Form.Item
                                name="layer"
                                label={(
                                    <Space direction="horizontal">
                                        <Tooltip placement="topLeft" title={t('plus button tooltip text')}>
                                            <Button onClick={() => openModal(true)} type="primary">+</Button>
                                        </Tooltip>
                                        <div>
                                            {t('current layer')}
                                        </div>
                                    </Space>
                                )}>
                                <Select
                                    defaultValue={layer}
                                    placeholder={t('select layer placehodler')}
                                    value={generalLayer}
                                    onChange={setGeneralLayer}
                                    showSearch
                                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                                    options={layerOptions?.map(x => {
                                        return {
                                            label: x?.name,
                                            value: x?.id
                                        }
                                    })} />
                            </Form.Item>
                        </Col>
                        {layer && layer > 0 && (
                            <>
                                <Col span={6} className="field">
                                    <Form.Item
                                        name="year"
                                        label={t('current years')}>
                                        <Select defaultValue={year} placeholder={t('select year placeholder')} allowClear>
                                            {
                                                [...this.getYears, 0].map(year => year === 0 ? null : (
                                                    <Select.Option value={year}>{year}</Select.Option>
                                                )
                                                )
                                            }
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item
                                        name="owner_user"
                                        label={t('current owner')}>
                                        <Select
                                            defaultValue={owner_user}
                                            placeholder={t('select owner placeholder')}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            allowClear
                                            options={fullUsersList?.map(usr => {
                                                return {
                                                    label: usr.name,
                                                    value: usr.id
                                                }
                                            })} />
                                    </Form.Item>
                                </Col>
                                <Col span={6} className="field">
                                    <Form.Item
                                        name="culture"
                                        label={t('current culture')}>
                                        <Select
                                            defaultValue={culture}
                                            placeholder={t('select culture placeholder')}
                                            showSearch
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            allowClear
                                            options={cultureList?.map(x => {
                                                return {
                                                    label: x?.name,
                                                    value: x?.name
                                                }
                                            })} />
                                    </Form.Item>
                                </Col>
                            </>
                        )}
                    </Row>

                    {layer && layer > 0 && (
                        <div className="registry-column-select">
                            <ColumnSelect
                                importantColumns={['field_num']}
                                defaultColumns={['field_num', 'owner_user', 'years', 'area', 'culture']}
                                columnsList={columnSelectOptions} activeColumns={activeColumns}
                                setActiveColumns={setActiveColumns} />
                        </div>
                        // <Row align="right" justify="center" gutter={12}>
                        //     <Col span={6} className="field">
                        //         <Button onClick={this.handleObjectsSetup} style={{ width: '100%', transform: 'translateY(11%)' }} type="primary">
                        //             {t('edit data button')}
                        //         </Button>
                        //     </Col>
                        //     <Col span={12} className="field">
                        //         <div className="warning-message">
                        //             {t('empty warning')}
                        //         </div>
                        //     </Col>
                        //     <Col span={4} className="field">
                        //         <ColumnSelect
                        //             importantColumns={['field_num']}
                        //             defaultColumns={['field_num', 'owner_user', 'years', 'area', 'culture']}
                        //             columnsList={columnSelectOptions} activeColumns={activeColumns}
                        //             setActiveColumns={setActiveColumns} />
                        //     </Col>
                        // </Row>
                    )}
                </Form>

                {layer && layer > 0 && (
                    <>
                        <Table
                            onChange={this.handleTableChange}
                            rowKey="uniqueRowKey"
                            scroll={activeColumns?.length >= 5 ? activeColumns?.length > 10 ? { x: 2500 } : { x: 1500 } : { x: 1000 }}
                            dataSource={this.filteredDataset}
                            columns={[...this.requestsColumns, actionButton]}
                            pagination={false} />
                        <TableFooter
                            isSized={false}
                            filterItems={this.filterItems}
                            showSizeChanger={false}
                            onSelect={this.onPageSizeChange}
                            onChange={this.onCurrentPageChange}
                            pageSize={page_size}
                            current={page}
                            rowDivider={ROW_DIVIDER.toString()}
                            dataSize={dataSize} />
                    </>
                )}

                <InfoMessage
                    visible={infoMessageVisible}
                    title={t('delete title')}
                    okBtn={t('delete')}
                    cancelBtn={t('cancel')}
                    onOk={this.removeCalculator}
                    onCancel={this.hideDeleteModal} />
            </div>
        )
    }
}
