import React, { useState } from 'react'
import {
  Popover,
  Tooltip,
  Button
} from 'antd'
import { DeleteOutlined } from '@ant-design/icons'

export default ({ deleteHandler, item, asViewer }) => {
  const [open, setOpen] = useState(false)

  const hide = () => {
    setOpen(false)
  }

  return (
    <Popover
      content={
        <div className='delete-confirm'>
          <div className='delete-confirm__question'>
            Вы действительно хотите удалить файл?
          </div>
          <div className='delete-confirm__footer'>
            <Button
              key="back"
              size="small"
              onClick={hide}
            >
              Отменить
            </Button>
            <Button
              key="submit"
              type="primary"
              size="small"
              danger
              onClick={() => {
                deleteHandler(item.id)
                hide()
              }}
            >
              Удалить
            </Button>
          </div>
        </div>
      }
      trigger="click"
      visible={open}
      onVisibleChange={(state) => setOpen(state)}
    >
      <Tooltip
        placement="bottom"
        title="Удалить"
      >
        <DeleteOutlined
          className={`upload-modal__item-icon ${asViewer ? 'clickable-viewer' : 'clickable'}`}
        />
      </Tooltip>
    </Popover>
  )
}
