import React, { useState } from 'react'
import MaskedInput from 'react-text-mask'

const CadasralNumberInput = React.forwardRef((props, ref) => {
  const [value, setValue] = useState(props.defaultValue)

  const mask = [
    /\d/, /\d/, ':', // AA — 2 цифры
    /\d/, /\d/, ':', // BB — 2 цифры
    /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, ':', // CCCCCCC — 6 или 7 цифр
    /\d/, /\d/, // KK — 2 цифры
  ]

  const changeHandler = () => {
    setValue(ref.current.textMaskInputElement.state.previousConformedValue)
  }

  return (
    <MaskedInput
      mask={mask}
      guide={true}
      showMask={true}
      ref={ref}
      placeholderChar={'_'}
      value={value}
      onBlur={props.onBlurHandler(value)}
      onChange={() => changeHandler()}
    />
  )
})

export default CadasralNumberInput
