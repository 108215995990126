import React, { useState, Component, useRef } from 'react'
import { withRouter } from 'react-router-dom'
import {
  DatePicker,
  Dropdown,
  Col,
  Form,
  Row,
  Input,
  Select,
  Button,
  Space,
  Menu
} from 'antd'
import {
  MoreOutlined,
  QuestionCircleOutlined,
} from '@ant-design/icons'
import moment from 'moment'

import cn from '../../../utils/cn'
import { wordEndingByCount, formatDate } from '../../../helpers'
import { Table, TableFooter, Tooltip } from '../../../components'
import NewDataForm from './new-data-form'
import CadasralNumberInput from './cadasral-number-input'

const MAX_RECORDS_IN_PAGINATOR = 40
const DATETIME_PARSE_FORMAT = 'DD.MM.YYYY'
const DATETIME_SEND_FORMAT = 'YYYY-MM-DD'
@withRouter
@cn('verification-area-calculators-table')
export default class CalculatorsTable extends Component {
  constructor (props) {
    super(props)
    this.timeoutId = null
  }

  componentDidMount () {
    this.timeoutId = setTimeout(() => {
      this.updatePaginationInfo(1, null)
    }, 500)
  }

  componentWillUnmount () {
    if (this.timeoutId) {
      clearTimeout(this.timeoutId)
    }
  }

  onCurrentPageChange = (newPage) => {
    this.updatePaginationInfo(newPage, null)
  };

  updatePaginationInfo = (page, filter, sortOrder) => {
    let sort
    if (sortOrder) {
      const { order, columnKey } = sortOrder
      if (order === 'ascend') {
        sort = columnKey
      } else if (order === 'descend') {
        sort = `-${columnKey}`
      }
    }
    const { updatePagination } = this.props
    updatePagination(page, filter, sort)
  };

  get layerColumns () {
    const {
      menuClickHandler = () => { },
    } = this.props
    return [
      {
        title: 'Наименование слоя',
        dataIndex: 'name',
        key: 'name',
        sorter: true,
        render: (value, row) => {
          return (
            <span
              className="hoverable-link"
              onClick={() => this.selectLayer(row?.layer_id_ghb)}
            >
              {value}
            </span>
          )
        }
      },
      {
        title: 'Количество объектов',
        dataIndex: 'num_of_objects',
        key: 'num_of_objects',
        sorter: true
      },
      {
        title: 'Дата последнего обновления',
        dataIndex: 'updated',
        key: 'updated',
        sorter: true,
        render: value => value ? formatDate(value) : null
      },
      {
        title: '',
        key: 'actions',
        render: (_, row) => {
          const [isActive, setIsActive] = useState()
          return {
            children: (
              <Dropdown
                overlay={(
                  <Menu onClick={() => setIsActive(false)}>
                    <Menu.Item onClick={() => menuClickHandler('updateLayer', row)}>
                      Обновить
                    </Menu.Item>
                    <Menu.Item onClick={() => menuClickHandler('deleteLayer', row)}>
                      Удалить
                    </Menu.Item>
                  </Menu>
                )}
                trigger={['click']}
                onVisibleChange={(visible) => {
                  setIsActive(visible)
                }}>
                <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
              </Dropdown>
            )
          }
        }
      }
    ]
  }

  get reportColumns () {
    const {
      areaTypes = () => [],
      openField = () => { },
      updateRow,
      menuClickHandler = () => { }
    } = this.props
    const areaTypesOptions = Array.isArray(areaTypes) ? areaTypes.slice() : []
    areaTypesOptions.push({
      label: 'Не указано',
      value: null
    })

    const isValidCadastralNumber = (value) => {
      return /^\d{2}:\d{2}:\d{7}:\d{2}$/.test(value)
    }

    const handleCadastralNumberBlur = (row, inputValue) => {
      inputValue = typeof inputValue === 'string' ? inputValue : ''
      inputValue = inputValue.replace(/[^\d:]/g, '')
      if (isValidCadastralNumber(inputValue)) {
        updateRow(row, 'cadastral_num', inputValue)
      }
    }

    return [
      {
        title: 'Номер участка',
        dataIndex: 'polygon_num',
        key: 'polygon_num',
        render: (val, row) => {
          return <Tooltip title="Автозаполняемое значение. Недоступно к редактированию">
            {val ? <a
              style={{ cursor: 'pointer' }}
              onClick={() => openField(row)}
            >{val}</a> : 'Не указано'}
          </Tooltip>
        }
      },
      {
        title: 'Дата выезда',
        dataIndex: 'scouted_date',
        key: 'scouted_date',
        width: 170,
        render: (val, row) => {
          return <DatePicker
            defaultValue={val ? moment(val) : null}
            placeholder="Выберите дату"
            format={DATETIME_PARSE_FORMAT}
            onChange={value => updateRow(row, 'scouted_date', value.format(DATETIME_SEND_FORMAT))}
          />
        }
      },
      {
        title: 'Кадастровый номер',
        dataIndex: 'cadastral_num',
        key: 'cadastral_num',
        width: 175,
        render: (val, row) => {
          const inputRef = useRef(null)
          return <CadasralNumberInput
            ref={inputRef}
            defaultValue={val}
            onBlurHandler={(inputVal) => handleCadastralNumberBlur(row, inputVal)}
          />
        }
      },
      {
        title: 'Вид угодья установленный',
        dataIndex: 'area_type_auto',
        key: 'area_type_auto',
        render: (val) => {
          const result = areaTypes.find(item => item.value === val)
          return <Tooltip title="Автозаполняемое значение. Недоступно к редактированию">
            {result ? result.name : 'Не указано'}
          </Tooltip>
        }
      },
      {
        title: 'Вид угодья фактический',
        dataIndex: 'area_type_fact',
        key: 'area_type_fact',
        render: (val, row) => {
          return <Select
            defaultValue={val ? Number(val) : null}
            placeholder="Выберите вид угодья"
            allowClear
            onChange={value => updateRow(row, 'area_type_fact', value)}
            options={areaTypesOptions}
          />
        }
      },
      {
        title: 'Площадь, га',
        dataIndex: 'area',
        key: 'area',
        render: (val) => {
          return <Tooltip title="Автозаполняемое значение. Недоступно к редактированию">
            {val ? val : 'Не указано'}
          </Tooltip>
        }
      },
      {
        title: 'Координаты центроида участка',
        dataIndex: 'centroid',
        key: 'centroid',
        render: (val) => {
          return <Tooltip title="Автозаполняемое значение. Недоступно к редактированию">
            {Array.isArray(val.coordinates) ? val.coordinates.join(' ') : 'Не указано'}
          </Tooltip>
        }
      },
      {
        title: 'Исполнитель',
        dataIndex: 'user',
        key: 'user',
        render: (val) => {
          return val.email ? val.email : 'Не указано'
        }
      },
      {
        title: 'Фотоотчет',
        dataIndex: 'number_of_photos',
        key: 'number_of_photos',
        render: (val, row) => {
          const count = Number(val)
          if (count && !Number.isNaN(count)) {
            return (
              <span
                className="hoverable-link"
                onClick={() => menuClickHandler('showPhotos', row)}
              >
                {count} фото
              </span>
            )
          } else {
            return 'Нет фото'
          }
        }
      },
      {
        title: 'Документы',
        dataIndex: 'number_of_documents',
        key: 'number_of_documents',
        render: (val, row) => {
          const count = Number(val)
          if (count && !Number.isNaN(count)) {
            return (
              <span
                className="hoverable-link"
                onClick={() => menuClickHandler('downloadDocuments', row)}
              >
                {count} {wordEndingByCount(count, 'файл', ['', 'a', 'ов'])}
              </span>
            )
          } else {
            return 'Нет документов'
          }
        }
      },
      {
        title: 'Комментарий',
        dataIndex: 'comment',
        key: 'comment',
        width: 200,
        render: (val, row) => {
          const inputRef = useRef(null)
          return <Input
            ref={inputRef}
            allowClear
            defaultValue={val}
            autoComplete="off"
            placeholder="Введите текст"
            onBlur={() => updateRow(row, 'comment', inputRef?.current.state.value)}
          />
        }
      },
      {
        key: 'actions',
        fixed: 'right',
        render: (_, row) => {
          const [isActive, setIsActive] = useState()
          return {
            children: (
              <Dropdown
                overlay={(
                  <Menu onClick={() => setIsActive(false)}>
                    <Menu.Item onClick={() => menuClickHandler('attachPhotos', row)}>
                      Прикрепить фотоотчет
                    </Menu.Item>
                    <Menu.Item onClick={() => menuClickHandler('attachDocuments', row)}>
                      Прикрепить документы
                    </Menu.Item>
                    {row.number_of_photos &&
                      <Menu.Item onClick={() => menuClickHandler('downloadPhotos', row)}>
                        Скачать фотоотчет на устройство
                      </Menu.Item>
                    }
                  </Menu>
                )}
                trigger={['click']}
                onVisibleChange={(visible) => {
                  setIsActive(visible)
                }}>
                <MoreOutlined style={{ color: isActive ? '#03B575' : '' }} />
              </Dropdown>
            )
          }
        }
      }
    ]
  }

  get filterItems () {
    const { totalSize, pageSize } = this.props
    const items = []

    for (let i = pageSize; i < totalSize && i <= MAX_RECORDS_IN_PAGINATOR; i += pageSize) {
      items.push({ value: i.toString(), title: i.toString() })
    }

    return items
  }

  handleTableChange = (pagination, filters, sorter, extra) => {
    this.updatePaginationInfo(1, {}, sorter)
  }

  selectLayer = (newLayerId) => {
    const {
      openLayer = () => { }
    } = this.props
    if (newLayerId) {
      openLayer(newLayerId)
    }
  }

  selectFilter = (filterCategory, newValue) => {
    if (!newValue) {
      this.updatePaginationInfo(1, {})
      return
    }
    this.updatePaginationInfo(1, {
      [`${filterCategory}.exact`]: newValue
    })
  }

  render () {
    const { cn } = this
    const {
      totalSize,
      tableLoading,
      openModal = () => { },
      openedLayer,
      pageSize,
      dataRows,
      areaTypes = [],
      currentPage,
      visibleLayers = []
    } = this.props

    const layerOptions = Array.isArray(visibleLayers) ? visibleLayers.map(x => ({
      label: x?.name,
      value: x?.layer_id_ghb
    })) : []

    return (!openedLayer && !layerOptions.length) ? (
      <NewDataForm openModal={openModal} />
    ) : (
      <div className={cn()}>
        <Form>

          <Row gutter={20}>
            <Col span={8} className="field">
              <Space direction="horizontal" style={{ marginBottom: '5px' }}>
                {!openedLayer && <Tooltip placement="topLeft" title="Подготовить объекты нового слоя к расчетам">
                  <Button onClick={() => openModal(true)} type="primary">+</Button>
                </Tooltip>}
                <div>
                  {openedLayer ? 'Слой' : 'Слои участков проверки'}
                </div>
                {!openedLayer && <Tooltip
                  title="Таблица хранит перечень слоев, доступных к использованию при работе в системе">
                  <QuestionCircleOutlined
                    style={{ color: 'rgba(0,0,0,0.45)', fontSize: '20px' }}
                  />
                </Tooltip>}
              </Space>
              {openedLayer && <Form.Item name="layer">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <Select
                    defaultValue={openedLayer}
                    placeholder="Выберите слой"
                    onChange={this.selectLayer}
                    showSearch
                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                    options={layerOptions}
                  />
                  <Tooltip placement="topLeft" title="Подготовить объекты нового слоя к расчетам">
                    <Button onClick={() => openModal(true)} type="primary">+</Button>
                  </Tooltip>
                </div>
              </Form.Item>}
            </Col>
            {openedLayer && (
              <>
                <Col span={8} className="field">
                  <Form.Item
                    name="area_type_fact"
                    label="Фактический вид угодья"
                  >
                    <Select
                      defaultValue={null}
                      placeholder="Выберите вид угодья"
                      onChange={value => this.selectFilter('area_type_fact', value)}
                      allowClear
                      options={areaTypes}
                    />
                  </Form.Item>
                </Col>
              </>
            )}
          </Row>
        </Form>

        {openedLayer && <div className='borshevik-table__header'>
          Участки проверки
        </div>}
        <Table
          onChange={this.handleTableChange}
          rowKey="id"
          dataSource={dataRows}
          columns={openedLayer ? [...this.reportColumns] : [...this.layerColumns]}
          pagination={false}
          loading={tableLoading}
        />
        {(openedLayer && dataRows.length > 0) && <TableFooter
          isSized={false}
          filterItems={this.filterItems}
          showSizeChanger={false}
          onChange={this.onCurrentPageChange}
          pageSize={pageSize}
          current={currentPage}
          rowDivider={pageSize.toString()}
          dataSize={totalSize}
        />}
      </div>
    )
  }
}
