import React, { useState, useEffect } from 'react'
import {
  Button,
  Modal
} from 'antd'
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  CloseOutlined
} from '@ant-design/icons'

import DeletePopover from './delete-popover'

export default ({
  isPhotoReport,
  items,
  initPosition,
  deleteHandler,
  closeHandler
}) => {
  const [currentPosition, setCurrentPosition] = useState(initPosition || 0)
  const [currentFile, setCurrentFile] = useState(null)
  const [isModalVisible, setModalVisible] = useState(true)

  const updateFile = () => {
    if (!items.length) return
    let position = currentPosition
    if (currentPosition === items.length) {
      position = currentPosition - 1
      setCurrentPosition(position)
    }
    const file = items[position]
    if (file && file.id !== currentFile?.id) {
      setCurrentFile(file)
    }
  }

  useEffect(() => {
    updateFile()
  }, [currentPosition, items])

  useEffect(() => {
    updateFile()
  }, [])

  const handleClose = () => {
    setModalVisible(false)
    if (closeHandler) {
      closeHandler()
    }
  }

  return (
    <Modal
      visible={isModalVisible}
      title={isPhotoReport ? 'Просмотр фотоотчета' : 'Просмотр прикрепленных документов'}
      className='upload-viewer-modal'
      width={848}
      closeIcon={<CloseOutlined onClick={handleClose} />}
      footer={<div className='upload-viewer-modal__footer'>
        <Button
          key="prev"
          disabled={items.length < 2 || currentPosition === 0}
          onClick={() => setCurrentPosition(currentPosition - 1)}
        >
          <ArrowLeftOutlined />
          Назад
        </Button>
        <Button
          key="next"
          disabled={items.length < 2 || currentPosition === (items.length - 1)}
          onClick={() => setCurrentPosition(currentPosition + 1)}
        >
          Далее
          <ArrowRightOutlined />
        </Button>
      </div>}
    >
      <div className='upload-viewer-modal__wrapper'>
        <img src={currentFile?.image} />
      </div>
      <div className='upload-viewer-modal__caption'>
        <div className='upload-viewer-modal__navigation'>
          ({currentPosition + 1}/{items.length})
        </div>
        <div className='upload-viewer-modal__file'>
          <div className='upload-viewer-modal__file-name'>
            {currentFile?.name}
          </div>
          <DeletePopover
            deleteHandler={deleteHandler}
            item={currentFile}
            asViewer={true}
          />
        </div>
      </div>
    </Modal>
  )
}
